import { render, staticRenderFns } from "./StackedCardsSwiper.vue?vue&type=template&id=11773f4c&scoped=true&"
import script from "./StackedCardsSwiper.vue?vue&type=script&lang=js&"
export * from "./StackedCardsSwiper.vue?vue&type=script&lang=js&"
import style0 from "./StackedCardsSwiper.vue?vue&type=style&index=0&id=11773f4c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11773f4c",
  null
  
)

/* custom blocks */
import block0 from "./StackedCardsSwiper.vue?vue&type=custom&index=0&blockType=docs&lang=md"
if (typeof block0 === 'function') block0(component)

export default component.exports